<!--
 * @FilePath: \zsw_new_edition\src\views\sign\index.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-01-11 10:24:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-08 14:24:07
-->
<template>
  <div class="signBox">
    <img src="../../assets/image/sign-bck.png" alt="" />
		<div class="signBtn" v-if="!token">
			<div class="typeItem" @click="login()">登录</div>
		</div>
    <div
      class="signBtn"
      v-if="
        joinStatus != '' &&
        joinStatus != 'checking' &&
        joinStatus != 'checkSuccess'
      "
    >
      <router-link :to="{ path: '/sign/personalSign' }" class="typeItem"
        >个人签约</router-link
      >
      <router-link :to="{ path: '/sign/companySign' }" class="typeItem"
        >企业签约</router-link
      >
    </div>
    <div class="signBtn" v-if="joinStatus == 'checking'" style="margin-top:0.25rem">
      <router-link :to="{ path: '/sign/personalSign' }" class="typeItem"
        >已申请入驻</router-link
      >
    </div>
    <div class="signBtn" v-if="joinStatus == 'checkSuccess'"  style="margin-top:0.25rem">
      <router-link :to="{ path: '/upload/uploadWork' }" class="typeItem"
        >签约成功</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkJoin } from "../../Api";
import { getToken } from "../../utils/auth";
export default {
  name: "SignIndex",
  data() {
    return {
			token: '',
      joinStatus: "",
    };
  },
	methods: {
		login() {
			this.$login();
		}
	},
  created() {
		this.token = getToken()
		if (!this.token) {
			this.$login();
			return
		}
    //判断入驻状态
    checkJoin({ token: getToken() }).then((res) => {
      this.joinStatus = res.data.message;
    });
  },
  computed: {
    ...mapGetters(["isLogin", "userInfo"]),
  },
  watch: {
    isLogin: function () {
      // 登录状态发生改变从新加载数据
      checkJoin({ token: getToken() }).then((res) => {
        this.joinStatus = res.data.message;
      });
    },
  },
};
</script>

<style scoped>
.signBox {
  position: relative;
}
.signBox img {
  z-index: -1;
  width: 100%;
}
.signBtn {
  position: absolute;
  width: 2.5rem;
  height: 1.08rem;
  left: 42.5%;
  top: 36%;
}
.signBtn .typeItem {
  width: 2.5rem;
  height: 0.44rem;
  background: linear-gradient(180deg, #6c63ff 0%, #9b96ff 100%);
  border-radius: 0.22rem;
  opacity: 1;
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.2rem;
  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
</style>